import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

import Whatsapp from "../images/whatsapp.svg"

export const query = graphql`
  {
    datoCmsBio {
      bio
      foto {
        fluid {
          src
        }
      }
      titulo
    }
  }
`

const Bio = ({ data }) => {
  const bio = data.datoCmsBio

  return (
    <Layout>
      <SEO title={bio.titulo} />

      <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
        <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-20">
          {bio.titulo}
        </h2>
        <div className="w-full lg:w-10/12 lg:flex mx-auto">
          <div className="w-full lg:w-1/3 rounded-lg p-5">
            <img src={bio.foto.fluid.src} alt={bio.titulo} className="" />
          </div>

          <div
            className="w-full lg:w-2/3 mt-5 lg:mt-0"
            dangerouslySetInnerHTML={{ __html: bio.bio }}
          />
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Bio
